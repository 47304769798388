import type { Step as CuratorOnboardingStep } from '~/stores/influencerOnboardingFlow'

export const useCuratorOnboardingFlow = () => {
  const { $pinia } = useNuxtApp()
  const influencer = useUserInfluencerStore($pinia).$state
  const tags = useUserInfluencerTagsStore($pinia).$state

  function wordCountCheck(str: string) {
    return str.split(/\s/g).filter((s) => s.length).length >= 15
  }

  const stepCompletionChecks: {
    [key in CuratorOnboardingStep]: (o: {
      influencer: IUserInfluencerState
      tags: UserInfluencerTagsState
    }) => boolean
  } = {
    'name-country': ({ influencer, tags }) =>
      !!influencer.entity && !!tags.identity.country.length,
    type: ({ tags }) => !!tags.identity.influencer_kind.length,
    opportunities: ({ influencer }) =>
      !!influencer.secondary_opportunities.length,
    bio: ({ influencer }) => wordCountCheck(influencer.description_en),
    'website-links': ({ influencer }) =>
      !!influencer.link ||
      Object.entries(influencer.media_links)
        .filter(([k, _]) => k !== 'id' && k !== 'pk')
        .some(([_, value]) => value),

    'similar-artists': ({ influencer }) =>
      influencer.similar_artists.length > 0,
    genres: ({ tags }) =>
      !!tags.liked.subgenre.length && !!tags.hated.subgenre.length,
    'lang-lyrics-release': ({ tags }) =>
      !!tags.exclusivity.lyrics_lang.length &&
      !!tags.exclusivity.track_age.length,
    picture: ({ influencer }) => influencer.has_profile_picture,
  }

  function stepIsCompleted(step: CuratorOnboardingStep): boolean {
    const checkCompletion = stepCompletionChecks[step]
    return checkCompletion ? checkCompletion({ influencer, tags }) : false
  }

  function getMissingStep(
    steps: CuratorOnboardingStep[],
  ): CuratorOnboardingStep {
    let missingStep: CuratorOnboardingStep = steps[0]
    steps.every((step: CuratorOnboardingStep) => {
      missingStep = step
      return stepIsCompleted(step)
    })
    return missingStep
  }
  return {
    wordCountCheck,
    getMissingStep,
  }
}
